import axios from "axios";

const getdoc = async (params) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}cms/get`,
      params,
      {}
    );
    console.log(response);

    return response;
  } catch (error) {
    console.log(error);

    throw error.response.data;
  }
};
export default getdoc;
