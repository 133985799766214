import Footer from "../Componants/Footer";
import Nav from "../Componants/Nav";
import about from "../Assets/about.png";

const About = () => {
  return (
    <>
      <div className="about_header">
        <Nav />
        <h1 className="about_title">About Us</h1>
      </div>
      <div className="about_info">
        {" "}
        <div className="about_data">
          <div>
            <h2 className="about_headings">
              Bridging Languages, Connecting Worlds
            </h2>
            <p className="about_des">
              Welcome to Tarjim, your trusted partner in seamless and
              high-quality translation services. Founded with a vision to bridge
              linguistic gaps, Tarjim serves as a dynamic platform that connects
              businesses, professionals, and individuals with certified
              translators proficient in a wide array of languages and
              specialized fields.{" "}
            </p>
            <h2 className="about_headings">Who We Are</h2>
            <p className="about_des">
              At Tarjim, we understand that effective communication transcends
              language barriers. Based in the Kingdom of Saudi Arabia, our
              platform is designed to cater to the diverse translation needs of
              a global clientele. Whether you're a multinational corporation
              seeking precise document translations, a legal firm requiring
              certified translations, or an individual needing personal
              documents translated, Tarjim is here to facilitate your
              requirements with unmatched professionalism and reliability.
            </p>
          </div>
          <img
            src={about}
            alt="Translator app"
            loading="lazy"
            className="about_image"
          />
        </div>
        <h2 className="about_headings">What We Do</h2>
        <p className="about_des">
          Tarjim offers a comprehensive suite of translation
        </p>
        <p className="about_des">
          services tailored to meet the specific needs of our clients:
        </p>{" "}
        <p className="about_des">
          Certified Translations: Our network comprises certified translators
          who ensure that all translations meet the highest standards of
          accuracy and compliance, essential for legal, medical, and official
          documents.
        </p>
        <p className="about_des">
          Diverse Language Support: From Arabic and English to over 50 other
          languages, Tarjim provides translation services across a broad
          spectrum of languages, accommodating both common and rare language
          pairs.
        </p>
        <p className="about_des">
          {" "}
          Specialized Expertise: We cater to various industries, including
          legal, medical, technical, financial, and marketing, ensuring that
          your translations are not only accurate but also contextually
          relevant.
        </p>
        <p className="about_des">
          {" "}
          Efficient Turnaround: Understanding the urgency of your projects,
          Tarjim guarantees timely delivery without compromising on quality,
          thanks to our streamlined processes and dedicated support team.{" "}
        </p>
        <h2 className="about_headings">Our Mission</h2>
        <p className="about_des">
          {" "}
          Our mission is to facilitate clear and effective communication across
          cultures and languages. By leveraging advanced technology and a
          dedicated network of professional translators, we aim to make
          high-quality translation services accessible, affordable, and reliable
          for everyone.{" "}
        </p>
        <p className="about_des">
          <h2 className="about_headings">Our Values</h2>
          Quality Assurance: We are committed to delivering translations that
          are not only accurate but also culturally and contextually
          appropriate. Our rigorous quality control processes ensure that every
          project meets the highest standards. Confidentiality and Security:
          Protecting your information is paramount. Tarjim adheres to strict
          data protection laws, including the Saudi Personal Data Protection Law
          (PDPL), ensuring that all your documents and data are handled with the
          utmost confidentiality and security.
        </p>
        <p className="about_des">
          {" "}
          Customer-Centric Approach: Your satisfaction is our priority. From the
          moment you submit your translation request to the final delivery, our
          support team is dedicated to providing a seamless and responsive
          experience. Sustainability and Responsibility: We promote the use of
          digital documents to reduce paper waste and participate in initiatives
          that foster language and cultural understanding, contributing to a
          more connected and sustainable world.
        </p>{" "}
        <h2 className="about_headings">Why Choose Tarjim?</h2>
        <p className="about_des">
          {" "}
          Certified and Experienced Translators: Our translators are not only
          certified but also possess extensive experience in their respective
          fields, ensuring that your translations are both accurate and
          specialized.{" "}
        </p>
        <p className="about_des">
          User-Friendly Platform: Our intuitive platform makes it easy to submit
          documents, receive quotes, and track your translation projects in
          real-time.{" "}
        </p>
        <p className="about_des">
          {" "}
          Transparent Pricing: With no hidden fees, Tarjim offers competitive
          pricing tailored to the scope and complexity of your translation
          needs.{" "}
        </p>
        <p className="about_des">
          Comprehensive Support: Whether you encounter technical issues or have
          specific queries about your project, our dedicated support team is
          available via email and WhatsApp/Telegram to assist you promptly.
        </p>
        <h2 className="about_headings">Join the Tarjim Community</h2>
        <p className="about_des">
          {" "}
          Whether you're a client seeking reliable translation services or a
          professional translator looking to expand your reach, Tarjim welcomes
          you to join our growing community. Experience the difference of
          working with a platform that values quality, security, and customer
          satisfaction above all.
        </p>{" "}
        <p className="about_des">
          {" "}
          Feel free to customize this content further to better align with
          Tarjim's specific branding, tone, and unique offerings. If you need
          any additional sections or modifications, please let me know!{" "}
        </p>
      </div>

      <Footer />
    </>
  );
};
export default About;
