import mada from "../Assets/Mada_Logo.png";
import apple from "../Assets/apple-pay.png";
import master from "../Assets/ma_symbol_opt_73_3x.png";
import visa from "../Assets/visa.png";

const Payment = () => {
  return (
    <>
      <section className="section3">
        <h1 className="section3_title">Secure Payments</h1>
        <p className="section3_dec">
          We ensure your transactions are safe, fast, and encrypted. Choose from
          a variety of payment methods for your convenience.
        </p>

        <div className="section3_payments">
          <div className="section3_cards">
            <img src={mada} alt="transactions" loading="lazy" />
          </div>
          <div className="section3_cards">
            <img src={apple} alt="transactions" loading="lazy" />
          </div>
          <div className="section3_cards">
            <img src={master} alt="transactions" loading="lazy" />
          </div>
          <div className="section3_cards">
            <img src={visa} alt="transactions" loading="lazy" />
          </div>
        </div>
      </section>
    </>
  );
};
export default Payment;
