import menu from '../Assets/menus.png';
import logo from '../Assets/logo.png';

const Nav = () => {
  const openExternalLink = () => {
    window.open('https://web.tarjim.pro/', '_blank');
  };

  return (
    <>
      <nav>
        <img src={logo} className="header_logo" alt="Get Your Documents Translated by Experts." loading="lazy" />
        <ul className="header_menus">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/AboutUs">About</a>
          </li>
          <li>Contact</li>
          <li>
            <button className="header_hostbtn" onClick={openExternalLink}>
              Become a Partner
            </button>
          </li>
          <li>
            <button className="header_lngbtn">
              <div></div>
              En <img src={menu} alt="Anywhere!Translated by Experts." loading="lazy" />
            </button>
          </li>
        </ul>
      </nav>
    </>
  );
};
export default Nav;
