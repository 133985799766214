import Header from "../Componants/Header";
import Whychoose from "../Componants/Whychoose";
import Whyworking from "../Componants/Whyworking";
import Footer from "../Componants/Footer";
import Payment from "../Componants/Payment";

const Home = () => {
  return (
    <>
      <Header />
      <Whychoose />
      <Whyworking />
      <Payment />
      <Footer />
    </>
  );
};
export default Home;
