import toggle from "../Assets/switch.png";
import rocket from "../Assets/rocket.png";
import graph from "../Assets/pie-chart.png";
import arrow from "../Assets/next.png";
import payment from "../Assets/data.png";
import headphones from "../Assets/headphones.png";
import language from "../Assets/translate.png";

const Whyworking = () => {
  return (
    <>
      <section className="section2">
        <div className="section2_left">
          <img src={language} alt="Translation" loading="lazy" />
        </div>

        <div className="section2_right">
          <h1 className="section2_title">Why working with Tarjim?</h1>
          <div className="section1_whybox_list">
            <div className="section1_whybox">
              <img
                src={toggle}
                alt="toggle"
                className="section1_whybox_icon"
                loading="lazy"
              />
              <h2 className="section2_whybox_title">Flixable working time</h2>
              <p className="section2_whybox_dec">
                Work with us anytime from anywhere.
              </p>
            </div>
            <div className="section1_whybox">
              <img
                src={payment}
                className="section1_whybox_icon"
                alt="Competitive toll"
                loading="lazy"
              />
              <h2 className="section2_whybox_title">Competitive toll</h2>
              <p className="section2_whybox_dec">
                Our charge rate is among the lowest.
              </p>
            </div>
            <div className="section1_whybox">
              <img
                src={arrow}
                className="section1_whybox_icon1"
                alt="Straightforward registration"
                loading="lazy"
              />
              <h2 className="section2_whybox_title">
                Straightforward registration
              </h2>
              <p className="section2_whybox_dec">
                How will your product change your customer's life
              </p>
            </div>
            <div className="section1_whybox">
              <img
                src={graph}
                className="section1_whybox_icon"
                alt="Track performance"
                loading="lazy"
              />
              <h2 className="section2_whybox_title">Track your performance</h2>
              <p className="section2_whybox_dec">
                See how well you do with data and charts.
              </p>
            </div>
            <div className="section1_whybox">
              <img
                src={headphones}
                className="section1_whybox_icon"
                alt="Help"
                loading="lazy"
              />
              <h2 className="section2_whybox_title">We are lisinening</h2>
              <p className="section2_whybox_dec">We are here to help always.</p>
            </div>
            <div className="section1_whybox">
              <img
                src={rocket}
                className="section1_whybox_icon"
                alt="Tarjim"
                loading="lazy"
              />
              <h2 className="section2_whybox_title">
                Expand your clients base
              </h2>
              <p className="section2_whybox_dec">
                Tarjim helps you to reach more clients wherever they are.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Whyworking;
