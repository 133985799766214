import Footer from "../Componants/Footer";
import Nav from "../Componants/Nav";

const Cancellation = () => {
  //   useEffect(() => {
  //     getData();
  //   }, []);

  //   const getData = async () => {
  //     try {
  //       const params = {
  //         cms_for: "PrivacyPolicy",
  //       };
  //       const response = await getdoc(params);
  //       setloading(false);
  //       setData(response.data.data);
  //       setSData(response.data.data.cms_detail_eng);
  //     } catch (error) {
  //       setloading(false);
  //       if (error.code === 401) {
  //         localStorage.clear();
  //         navigate("/");
  //       }
  //     }
  //   };
  return (
    <>
      <div className="Terms_Nav">
        {" "}
        <Nav />
      </div>
      <div className="Terms_info">
        <p className="policy_title">Cancellation Policy</p>
        <ul className="policy_list">
          <li>
            <strong>Within [30 minutes]</strong>of order placement elapsed: You
            may cancel the order and receive a full refund.
          </li>
          <li>
            <strong>
              After (30) minutes but before (60) minutes of order time has
              elapsed:
            </strong>
            Cancellation will incur a fee of <strong>(15%)</strong> of the order
            cost.
          </li>
          <li>
            <strong>After (60) minutes of order time has elapsed:</strong>{" "}
            Orders cannot be canceled, and Tarjim has the right in the full
            payment regardless of the client intension to continue or not.
          </li>
        </ul>
        <p className="policy_title">Refunds</p>
        <ul>
          <li>
            Refunds, if applicable, will be processed within{" "}
            <strong>(10) business days </strong>
            using the original payment method.
          </li>
          <li>
            Any fees incurred due to refund processing are your responsibility.
          </li>
          <li>
            Refunds are subject to the terms of the PAYMENT GATEWAY SERVICES
            AGREEMENT with SNB Bank.
          </li>
        </ul>
      </div>
      {/* <div
        className="Terms_info"
        dangerouslySetInnerHTML={{ __html: Sdata }}
      ></div> */}
      <Footer />
      {/* {loading && <Loader />} */}
    </>
  );
};
export default Cancellation;
