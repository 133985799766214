import time from "../Assets/24-hours.png";
import payment from "../Assets/credit-card.png";
import En from "../Assets/a.png";
import Hn from "../Assets/letter-a.png";
import Jp from "../Assets/alphabet.png";
import Ar from "../Assets/alif.png";
import compass from "../Assets/compass.jpg";
import refund from "../Assets/refund.png";
import click from "../Assets/click.png";
import trophy from "../Assets/trophy.png";
import "./Whychoose.css";

const Whychoose = () => {
  return (
    <>
      <section className="section1">
        <div className="section1_left">
          <h1 className="section1_title">Why Choose Tarjim?</h1>
          <div className="section1_whybox_list">
            <div className="section1_whybox">
              <img
                src={time}
                className="section1_whybox_icon"
                alt="Any Time, anywhere"
                loading="lazy"
              />
              <h2 className="section1_whybox_title">Any Time, anywhere</h2>
              <p className="section1_whybox_dec">
                Tarjim is availabe for you whenever you are 24/7.
              </p>
            </div>
            <div className="section1_whybox">
              <img
                src={payment}
                className="section1_whybox_icon"
                alt="Flexiable Payement"
                loading="lazy"
              />
              <h2 className="section1_whybox_title">Flexiable Payement</h2>
              <p className="section1_whybox_dec">
                We accept multiple payment options.
              </p>
            </div>
            <div className="section1_whybox">
              <div className="section1_iconlist">
                <img
                  src={Ar}
                  className="section1_whybox_icon1"
                  alt="Multiple Languages"
                  loading="lazy"
                />
                <img
                  src={En}
                  className="section1_whybox_icon2"
                  alt="Multiple Languages"
                  loading="lazy"
                />
                <img
                  src={Hn}
                  className="section1_whybox_icon3"
                  alt="Multiple Languages"
                  loading="lazy"
                />
                <img
                  src={Jp}
                  className="section1_whybox_icon4"
                  alt="Multiple Languages"
                  loading="lazy"
                />
              </div>
              <h2 className="section1_whybox_title">Multiple Languages</h2>
              <p className="section1_whybox_dec">
                We offers many languages and keep adding more.
              </p>
            </div>
            <div className="section1_whybox">
              <img
                src={trophy}
                className="section1_whybox_icon"
                alt="Guarantee"
                loading="lazy"
              />
              <h2 className="section1_whybox_title">Guarantee Quality</h2>
              <p className="section1_whybox_dec">
                We only work with professionals.
              </p>
            </div>
            <div className="section1_whybox">
              <img
                src={click}
                className="section1_whybox_icon"
                alt="Easy Use"
                loading="lazy"
              />
              <h2 className="section1_whybox_title">Easy To Use</h2>
              <p className="section1_whybox_dec">
                No need for instruction to use our service.
              </p>
            </div>
            <div className="section1_whybox">
              <img
                src={refund}
                className="section1_whybox_icon"
                alt="refund"
                loading="lazy"
              />
              <h2 className="section1_whybox_title">Refund</h2>
              <p className="section1_whybox_dec">
                Full refund if quality is inadequate.
              </p>
            </div>
          </div>
        </div>
        <div className="section1_right">
          <img src={compass} alt="compass" loading="lazy" />
        </div>
      </section>
    </>
  );
};
export default Whychoose;
