import logo from "./logo.svg";
import "./App.css";
import Home from "./Page/Home";
import { Route, Routes } from "react-router-dom";
import TermAgency from "./Page/TermsAgency";
import Terms from "./Page/Terms";
import Privacy from "./Page/Privacypolicy";
import Cancellation from "./Page/CancellationPolicy";
import About from "./Page/About";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element=<Home /> />
        <Route path="/TermsforUser" element=<Terms /> />
        <Route path="/TermsforTraslator" element=<TermAgency /> />
        <Route path="/PrivacyPolicy" element=<Privacy /> />
        <Route path="/CancellationPolicy" element=<Cancellation /> />
        <Route path="/AboutUs" element=<About /> />
      </Routes>
    </>
  );
}

export default App;
