import apple from "../Assets/apple.png";
import google from "../Assets/social.png";
import cer from "../Assets/Cerified.png";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import Nav from "./Nav";

const Header = () => {
  return (
    <>
      <header>
        <Nav />
        <div className="header_main">
          <div className="header_main_left">
            <h1 className="header_left_title">
              Certified Document <br />
              Translation Online
            </h1>
            <p className="header_left_desc">Anytime, Anywhere!</p>
            <p className="header_left_desc">
              Get Your Documents Translated by Experts.
            </p>
            <p className="header_startbtn">START YOUR TRANSLATION NOW!</p>
            <div className="header_downloadbtns">
              <button className="header_download">
                <img
                  src={google}
                  alt="Translator app download"
                  className="header_download_img"
                  loading="lazy"
                />
                Google Play
              </button>
              <button className="header_download">
                <img
                  src={apple}
                  alt="Translator app download"
                  className="header_download_img2"
                  loading="lazy"
                />
                App Store
              </button>
            </div>
          </div>
          <div className="header_main_right">
            <img
              src={cer}
              alt="Certified Document Translation Online Anytime"
            />
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
