import Header from "../Componants/Header";
import Footer from "../Componants/Footer";
import getdoc from "../Service/Mainservice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "../Componants/Nav";
import "./Terms.css";
import Loader from "../Loader/Loader";

const Terms = () => {
  const [data, setData] = useState({});
  const [Sdata, setSData] = useState("");
  const navigate = useNavigate();
  const [loading, setloading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const params = {
        cms_for: "TermsConditionCustomer",
      };
      const response = await getdoc(params);
      console.log(response.data.data);
      setloading(false);
      setData(response.data.data);
      setSData(response.data.data.cms_detail_eng);
    } catch (error) {
      setloading(false);
      if (error.code === 401) {
        localStorage.clear();
        navigate("/");
      }
    }
  };
  return (
    <>
      <div className="Terms_Nav">
        {" "}
        <Nav />
      </div>

      <div
        className="Terms_info"
        dangerouslySetInnerHTML={{ __html: Sdata }}
      ></div>
      <Footer />
      {loading && <Loader />}
    </>
  );
};
export default Terms;
